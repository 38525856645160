body, html {
  font-family: 'Circular Std', Helvetica, sans-serif;
}

.slider-container {
  margin-bottom: 60px;
  position: relative;
}

.slick-dots {
  bottom: -40px;
}

.slick-dots li {
  margin: 0 8px;
}

.custom-dot {
  width: 8px;
  height: 8px;
  background-color: #e2e8f0; /* Light gray for inactive dots */
  border-radius: 50%;
  transition: all 0.3s ease;
}

.slick-active .custom-dot {
  background-color: #3b82f6; /* Blue for active dot */
  width: 24px;
  border-radius: 4px;
}

.slick-slide {
  padding: 20px;
  transition: all 0.3s ease;
}

.slick-center {
  transform: scale(1.05);
}

/* Add a subtle gradient overlay to the slider background */
.slider-container::before {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  background: linear-gradient(135deg, rgba(139, 92, 246, 0.1), rgba(79, 70, 229, 0.1));
  z-index: -1;
  border-radius: 30px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .slider-container {
    margin: 0 0 60px 0;
  }
}

/* Loom Integration Section Styles */
.bg-gradient-to-r.from-gray-900.to-indigo-900 {
  position: relative;
  overflow: hidden;
}

.bg-gradient-to-r.from-gray-900.to-indigo-900::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: url('path-to-your-texture-image.png'); Add a subtle texture */
  opacity: 0.05;
  z-index: 0;
}

.bg-gradient-to-r.from-gray-900.to-indigo-900 > div {
  position: relative;
  z-index: 1;
}

.bg-gradient-to-r.from-gray-900.to-indigo-900 h3 {
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.bg-gradient-to-r.from-gray-900.to-indigo-900 p {
  text-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

/* ... (rest of the styles) */

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

/* Custom max-width class */
.max-w-custom {
    max-width: 100rem; /* Set to your desired value */
}

hr {
  border: none; /* Remove default border */
  height: 1px; /* Set height */
  background-color: #e5e7eb; /* Light gray color */
  margin: 2rem 0; /* Add vertical spacing */
}
